import "./detail-page.scss";
import "./dynamic-detail-page.scss";

import cn from "clsx";
import { SimpleTheme } from "design-system/utils/theme";
import {
  BaseLayout,
  SiteHeaderComponent,
} from "design-system/components/layouts/base";
import { ExtractLazyComponent } from "design-system/components/system/lazy-component/lazy-component";
import { BreadcrumbsProps } from "design-system/components/primitives/breadcrumbs/breadcrumbs";
import { SiteFooterProps } from "design-system/components/universal/site-footer/site-footer";
import {
  DetailPageBody,
  DetailPageBodyProps,
} from "design-system/components/pages/detail-page/detail-page-body/detail-page-body";
import { ReactNode } from "react";

export type DetailPageTopper = ExtractLazyComponent<
  | "CardTopper"
  | "CourseTopper"
  | "SimplePageTopper"
  | "EventTopper"
  | "MediaTopper"
  | "StatisticsTopper"
  | "SplitTopper"
  | "ProfileTopper"
>;

export interface DynamicDetailPageTopper {
  pageTitle?: string;
  pageDescription?: string;
  topper?: ReactNode;
}

export interface DetailPageProps {
  header?: SiteHeaderComponent;
  topper?: DetailPageTopper;
  dynamicTopper?: DynamicDetailPageTopper;
  breadcrumbs?: BreadcrumbsProps;
  body?: DetailPageBodyProps;
  blocks?: DetailPageBodyProps;
  footer?: SiteFooterProps;
  theme?: SimpleTheme;
}

export function DetailPage({
  header,
  topper,
  dynamicTopper,
  breadcrumbs,
  body,
  blocks,
  footer,
  theme,
}: DetailPageProps) {
  const layoutClass = cn(
    "hbs-page",
    "hbs-detail-page",
    topper?.name && `hbs-page--${topper.name}`,
    dynamicTopper?.pageTitle && "hbs-dynamic-page",
  );

  // const blocksProp = { blocks: blocks || {}}

  return (
    <BaseLayout className={layoutClass} footer={footer} header={header}>
      {body && (
        <DetailPageBody
          breadcrumbs={breadcrumbs}
          topper={topper}
          reactTopper={dynamicTopper?.topper}
          theme={theme}
          {...body}
          {...blocks}
        />
      )}
    </BaseLayout>
  );
}
