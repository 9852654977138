import { ReactNode } from "react";
import cn from "clsx";
import "./detail-page-body.scss";

import { SimpleTheme } from "design-system/utils/theme";
import {
  Breadcrumbs,
  BreadcrumbsProps,
} from "design-system/components/primitives/breadcrumbs/breadcrumbs";
import { LazyComponent } from "design-system/components/system/lazy-component/lazy-component";
import { DetailPageTopper } from "../detail-page";

export interface DetailPageBodyProps {
  breadcrumbs?: BreadcrumbsProps;
  topper?: DetailPageTopper;
  reactTopper?: ReactNode;
  children: ReactNode;
  blocks?: ReactNode;
  leftRail?: ReactNode;
  rightRail?: ReactNode;
  theme?: SimpleTheme;
  preFooterContent?: ReactNode;
}

export function DetailPageBody({
  breadcrumbs,
  topper,
  reactTopper,
  children,
  blocks,
  leftRail,
  rightRail,
  preFooterContent,
  theme = "light",
}: DetailPageBodyProps) {
  return (
    <main id="skipto-main" tabIndex={-1}>
      {topper && <LazyComponent {...topper} />}
      {reactTopper}
      {breadcrumbs && <Breadcrumbs {...breadcrumbs} theme={theme} />}
      <div className="hbs-detail-page-body__wrapper" data-theme={theme}>
        <div
          className={cn(
            "hbs-detail-page-body",
            rightRail ? "hbs-detail-page-body--has-right-rail" : null,
          )}
        >
          <aside
            className="hbs-aside-wrapper hbs-aside-left"
            aria-label="Table of contents"
            aria-hidden={!leftRail}
          >
            {leftRail}
          </aside>
          <div className="hbs-detail-page-body__main">
            <section
              id="skipto-body"
              className="hbs-page-body hbs-detail-page-body__content"
              tabIndex={-1}
            >
              {children}
              {blocks}
            </section>
          </div>
          <aside
            className="hbs-aside-wrapper hbs-aside-right"
            aria-label="Additional Information"
            aria-hidden={!rightRail}
          >
            {rightRail}
          </aside>
        </div>
      </div>
      <div className="hbs-detail-page-body__extra-content">
        <div className="hbs-detail-page-body__extra-content-main">
          <div className="hbs-page-body hbs-detail-page-body__extra-content-inner">
            {preFooterContent}
          </div>
        </div>
      </div>
    </main>
  );
}
